import React from 'react';
import ReactPlayer from 'react-player';
import 'aos/dist/aos.css';
import '../styles/SpeakfestSection.css';

const YouTubeSection = () => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <div className="youtube-section">
      <div className="youtube-header" data-aos="zoom-in">
        <img 
          src={`${publicUrl}/media/encabezados/speakfest.png`} 
          alt="Speakfest 2024" 
          className="header-image" 
        />
      </div>
      <div className="youtube-player-container" data-aos="zoom-in" data-aos-delay="350">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=-a3YnoRhprM" // Reemplaza con la URL de tu video
          controls
          className="react-player"
          width="100%"  // Ajusta el ancho del reproductor
          height="450px"  // Ajusta la altura del reproductor según tus necesidades
        />
      </div>
      <div className="youtube-header" data-aos="zoom-in">
        <img 
          src={`${publicUrl}/media/encabezados/graduaciones2024.png`} 
          alt="Speakfest 2024" 
          className="header-image" 
        />
      </div>
      <div className="youtube-player-container" data-aos="zoom-in" data-aos-delay="350">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=k50SnQ71yng" // Reemplaza con la URL de tu video
          controls
          className="react-player"
          width="100%"  // Ajusta el ancho del reproductor
          height="550px"  // Ajusta la altura del reproductor según tus necesidades
        />
      </div>
    </div>
    
  );
};

export default YouTubeSection;
